@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button.bg-transparent:hover svg,
button.bg-transparent:hover path {
  fill: #fff;
  @apply duration-200 ease-in;
}
svg {
  @apply align-middle;
}
.trending-searches-tags-slider .slick-slide > div {
  @apply inline-block mr-3;
}
.trending-searches-tags-slider .slick-arrow.slick-next {
  height: 100%;
  position: absolute;
  right: 0;
  top: 17px;
}
.trending-searches-tags-slider .slick-arrow.slick-next::before {
  position: absolute;
  background-image: url('./assets/svg/slick-arrow.svg');
  background-repeat: no-repeat;
  background-position: center right;
  height: 100%;
  top: 0;
  width: 128px;
  right: 0;
  z-index: 1;
  content: '';
}
.trending-searches-tags-slider .slick-arrow.slick-disabled {
  display: none !important;
}
.trending-searches-tags-slider .slick-arrow.slick-next:after {
  content: '';
  background: linear-gradient(270deg, #fff 18.41%, rgba(255, 255, 255, 0) 100%);
  width: 128px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.trending-searches-tags-slider .slick-arrow.slick-prev:after {
  content: '';
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 18.41%,
    #ffffff 100%
  );
  width: 128px;
  height: 34px;
  position: absolute;
  left: 25px;
  top: -7px;
}
.trending-searches-tags-slider .slick-arrow.slick-prev {
  z-index: 1;
}
.trending-searches-tags-slider .slick-arrow.slick-prev::before {
  position: absolute;
  background-image: url('./assets/svg/slick-prev.svg');
  background-repeat: no-repeat;
  background-position: center left 10px;
  height: 34px;
  top: -8px;
  width: 128px;
  left: 16px;
  z-index: 1;
  content: '';
}
.store svg {
  @apply w-full;
}

/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-primary;
  border-radius: 50px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-primary;
}
